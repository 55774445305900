import { Either, isLeft } from "fp-ts/Either";
import * as t from "io-ts";
import { fromNewtype } from "io-ts-types/fromNewtype";
import { PathReporter } from "io-ts/PathReporter";
import { Newtype, iso } from "newtype-ts";

// ============================================================================
// Utils
// ============================================================================
export const check = <T>(result: Either<t.Errors, T>): T => {
    if (isLeft(result)) {
        const msg = PathReporter.report(result).join("\n\n");
        console.log(msg);
        throw new Error(msg);
    }
    return result.right;
};

export const nullable = <RT extends t.Mixed>(type: RT) => {
    return t.union([t.null, type]);
};

// ============================================================================
// ID Types
// ============================================================================
export type LocationID = Newtype<
    { readonly LocationID: unique symbol },
    number
>;
export const LocationID = fromNewtype<LocationID>(t.number);
export const isoLocationID = iso<LocationID>();

// ============================================================================
// Coordinates & Distances
// ============================================================================
export type Latitude = Newtype<{ readonly Latitude: unique symbol }, number>;
export const Latitude = fromNewtype<Latitude>(t.number);
export const isoLatitude = iso<Latitude>();

export type Longitude = Newtype<{ readonly Longitude: unique symbol }, number>;
export const Longitude = fromNewtype<Longitude>(t.number);
export const isoLongitude = iso<Longitude>();

export type Meters = Newtype<{ readonly Meters: unique symbol }, number>;
export const Meters = fromNewtype<Meters>(t.number);
export const isoMeters = iso<Meters>();

export type Kilometers = Newtype<
    { readonly Kilometers: unique symbol },
    number
>;
export const Kilometers = fromNewtype<Kilometers>(t.number);
export const isoKilometers = iso<Kilometers>();

export type Miles = Newtype<{ readonly Miles: unique symbol }, number>;
export const Miles = fromNewtype<Miles>(t.number);
export const isoMiles = iso<Miles>();

export const LatLng = t.type({
    lat: Latitude,
    lng: Longitude,
});
export type LatLng = t.TypeOf<typeof LatLng>;

export const fromGoogleLatLng = (point: google.maps.LatLng): LatLng => {
    return {
        lat: isoLatitude.wrap(point.lat()),
        lng: isoLongitude.wrap(point.lng()),
    };
};

export const toGoogleLatLng = (point: LatLng): google.maps.LatLng => {
    return new google.maps.LatLng({
        lat: isoLatitude.unwrap(point.lat),
        lng: isoLongitude.unwrap(point.lng),
    });
};

// ============================================================================
// Locations
// ============================================================================
export const Phone = t.type({
    national: t.string,
    e164: t.string,
});
export type Phone = t.TypeOf<typeof Phone>;

export const Location = t.type({
    id: LocationID,
    display_name: t.string,
    location_type: t.string,
    status: t.string,
    address_summary: t.string,
    line1: t.string,
    line2: t.string,
    line3: t.string,
    city: t.string,
    state: t.string,
    postcode: t.string,
    country: t.string,
    location: LatLng,
    phone: nullable(Phone),
    website: nullable(t.string),
});
export type Location = t.TypeOf<typeof Location>;

export const LocationProximity = t.type({
    distance: Meters,
    location: Location,
});
export type LocationProximity = t.TypeOf<typeof LocationProximity>;

export const LocationQueryResponse = t.type({
    locations: t.array(LocationProximity),
});
export type LocationQueryResponse = t.TypeOf<typeof LocationQueryResponse>;
