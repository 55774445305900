import React, { useContext, useEffect, useRef, useState } from "react";
import { jt, t } from "ttag";

import { Context, constants, reverse } from "@reactivated";

import { CSRF_HEADER, loadCSRFToken } from "@thelabnyc/thelabui/src/utils/api";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { ButtonPrimary } from "../Clickables";
import { LogoLoader } from "../LogoLoader";

import styles from "./forms.module.scss";

export const FormType = constants["server.cms.blocks.forms.Forms"];
export type FormType = (typeof FormType)[keyof typeof FormType];

export interface FormLayout {
    /**
     * Naming is hard.
     * Solo = the form exists basically entirely on its own
     * Complementary = the form exists in context with some other stuff
     */
    layout: "solo" | "complementary" | "three-column";
}

interface CRMForm extends FormLayout {
    type: FormType;
    className?: string;
    title?: string;
    successState?: React.JSX.Element;
    onSubmit?: (resp: Response) => void;
    children: (
        disabled: boolean,
        legal: React.JSX.Element,
    ) => React.JSX.Element;
}

const Legal = ({ href }: { href: string }) => {
    const privacy = (
        <a key="privacy-link" href={href} className={styles.textLink}>
            {t`Privacy Policy`}
        </a>
    );

    return (
        <p className={styles.legal}>
            {jt`By clicking the button below, you agree to the ${privacy} and to receiving emails from Signature Kitchen Suite with news, special offers, and future messages that may interest you.`}
        </p>
    );
};

const DefaultSuccessState = () => (
    <>
        <h2 className={styles.title}>{t`Thank you for contacting us!`}</h2>
        <p>
            {t`Your message was sent and we will be in touch with you shortly.`}
        </p>
    </>
);

export const CRMForm = (props: CRMForm) => {
    const loadingRef = useRef<HTMLDivElement | null>(null);
    const successRef = useRef<HTMLDivElement | null>(null);
    const failureRef = useRef<HTMLDivElement | null>(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [failure, setFailure] = useState(false);
    const context = useContext(Context);

    useEffect(() => {
        if (loading === true && loadingRef.current) {
            loadingRef.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
    }, [loading]);

    useEffect(() => {
        if (success === true && successRef.current) {
            successRef.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
    }, [success]);

    useEffect(() => {
        if (failure === true && failureRef.current) {
            failureRef.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
    }, [failure]);

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setLoading(true);

        const action = event.currentTarget.action;
        const data = new FormData(event.currentTarget);
        const resp = await fetch(action, {
            method: event.currentTarget.method,
            headers: {
                [CSRF_HEADER]: await loadCSRFToken(),
            },
            body: data,
        });

        if (resp.ok) {
            setLoading(false);
            setSuccess(true);
        } else {
            setLoading(false);
            setFailure(true);
        }

        if (props.onSubmit) {
            props.onSubmit(resp);
        }
    };

    const pageTitle = props.title
        ? props.title
        : typeof document !== "undefined"
          ? document.title
          : "";

    return (
        <form
            className={concatClassNames([
                props.layout === "solo" ? styles.soloForm : undefined,
                props.layout === "complementary"
                    ? styles.complementaryForm
                    : undefined,
                props.layout === "three-column"
                    ? styles.threeColumnForm
                    : undefined,
                loading ? styles.loading : undefined,
                props.className,
            ])}
            method="post"
            onSubmit={onSubmit}
            action={reverse("cms-api:submit-form", {
                form_type: props.type,
            })}
            acceptCharset="utf-8"
        >
            {loading && (
                <div ref={loadingRef} className={styles.loadingUI}>
                    {t`Sending`}
                    <LogoLoader className={styles.loader} />
                </div>
            )}
            {success && (
                <div ref={successRef} className={styles.successUI}>
                    {props.successState ?? <DefaultSuccessState />}
                </div>
            )}
            {failure && (
                <div ref={failureRef} className={styles.failureUI}>
                    <h2 className={styles.title}>{t`Something went wrong.`}</h2>
                    <p>{t`Please try again later.`}</p>
                    <ButtonPrimary onPress={() => setFailure(false)}>
                        {t`Return to Form`}
                    </ButtonPrimary>
                </div>
            )}
            <input type="hidden" name="locale" value={context.LOCALE} />
            <input type="hidden" name="page_title" value={pageTitle} />
            <input type="hidden" name="referer" value={context.request.url} />
            {props.children(
                loading === true,
                <Legal href={context.wagtail_links.privacy_policy} />,
            )}
        </form>
    );
};
