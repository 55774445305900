import React from "react";

import { templates } from "@reactivated";

import { RichText } from "../RichText";

import styles from "./index.module.scss";

type PressReleasePage = templates.PressReleasePageTemplate["page"];

export const PressRelease = (props: { page: PressReleasePage }) => {
    return (
        <article
            className={styles.container}
            itemScope={true}
            itemType="http://schema.org/Article"
        >
            <div className={styles.inner}>
                <div className={styles.content}>
                    <h1 className={styles.header}>{props.page.title}</h1>
                    {props.page.caption && (
                        <RichText
                            className={styles.captionText}
                            value={props.page.caption.html}
                        />
                    )}
                    {props.page.body && (
                        <RichText value={props.page.body.html} />
                    )}
                </div>
            </div>
        </article>
    );
};
