import React from "react";
import { VisuallyHidden, usePreventScroll } from "react-aria";

import { ServerCmsContextProcessorsNavigationItem as NavigationItem } from "@reactivated";

import { Clickable } from "../Clickables";
import { Svg } from "../Svg";
import { NavAd } from "./NavAd";
import { getChildren } from "./utils";

import styles from "./nav-popover.module.scss";

interface Props {
    parent: NavigationItem;
    nav: NavigationItem[];
    menuId: string;
    mobileDesign: boolean;
    setActiveMenu: (menuId: string | null) => void;
}

export const NavPopover = ({
    parent,
    nav,
    menuId,
    mobileDesign,
    setActiveMenu,
}: Props) => {
    /**
     * If this branch of the nav has third-level children, then we want
     * the eyebrow to be a level 2 title, and everything under it will be
     * level 3 links. If not, then the eyebrow will be a level 1 title,
     * and everything under it will be level 2 links, with a "view all"
     * link tacked onto the bottom.
     */
    const l3ChildrenLength = getChildren(parent, nav).reduce(
        (accumulator, current) =>
            accumulator + getChildren(current, nav).length,
        0,
    );

    const l2Style = mobileDesign ? styles.l2Mobile : styles.l2;
    const l3Style = mobileDesign ? styles.l3Mobile : styles.l3;

    const linkList =
        l3ChildrenLength > 0 ? (
            getChildren(parent, nav).map((l2child) => (
                <li key={l2child.path}>
                    <div className={l2Style}>{l2child.title}</div>
                    <ul className={styles.l3List}>
                        {getChildren(l2child, nav).map((l3child) => (
                            <li key={l3child.path}>
                                <Clickable
                                    className={l3Style}
                                    href={l3child.url}
                                >
                                    {l3child.title}
                                </Clickable>
                            </li>
                        ))}
                        <li>
                            <Clickable className={l3Style} href={l2child.url}>
                                View all {l2child.title}
                                <Svg name="caret-right" aria-hidden="true" />
                            </Clickable>
                        </li>
                    </ul>
                </li>
            ))
        ) : (
            <li>
                <div className={l2Style}>{parent.title}</div>
                <ul className={styles.l3List}>
                    {getChildren(parent, nav).map((l2child) => (
                        <li key={l2child.path}>
                            <Clickable className={l3Style} href={l2child.url}>
                                {l2child.title}
                            </Clickable>
                        </li>
                    ))}
                </ul>
            </li>
        );

    usePreventScroll({ isDisabled: false });

    return (
        <>
            <div
                className={mobileDesign ? styles.mobilePopover : styles.popover}
                id={menuId}
            >
                <ul
                    className={
                        mobileDesign ? styles.l2ListMobile : styles.l2List
                    }
                >
                    {linkList}
                    {parent.nav_ads.map((ad, index) => (
                        <li key={`nav-ad-${parent.path}-${index}`}>
                            <NavAd {...ad} />
                        </li>
                    ))}
                </ul>
            </div>
            <Clickable
                className={!mobileDesign ? styles.underlay : ""}
                onPress={() => setActiveMenu(null)}
            >
                <VisuallyHidden>Close Nav Menu</VisuallyHidden>
            </Clickable>
        </>
    );
};
